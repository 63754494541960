import React, {useState, useEffect } from 'react'
import './weather.css'

import img_24a from './images/24a.png'
import img_22a from './images/22a.png'
import img_21a from './images/21a.png'
import img_23a from './images/23a.png'
import img_24b from './images/24b.png'
import img_22b from './images/22b.png'
import img_21b from './images/21b.png'
import img_23b from './images/23b.png'
import img_16 from './images/16.png'
import img_15 from './images/15.png'
import img_14 from './images/14.png'
import img_13 from './images/13.png'
import img_12 from './images/12.png'
import img_9 from './images/9.png'
import img_7 from './images/7.png'
import img_6 from './images/6.png'
import img_5 from './images/5.png'
import img_8 from './images/8.png'
import img_3 from './images/3.png'
import img_2 from './images/2.png'
import img_sad from './images/sadsun.png'


function GetWeatherImage(number, is_day) {
  const imagesDay = {
    0: img_24a,
    1: img_22a,
    2: img_21a,
    3: img_23a,
    45: img_16,
    48: img_16,
    51: img_15,
    53: img_15,
    55: img_14,
    56: img_13,
    57: img_13,
    61: img_12,
    63: img_12,
    65: img_9,
    66: img_8,
    67: img_3,
    71: img_7,
    73: img_7,
    75: img_6,
    77: img_5,
    80: img_12,
    81: img_12,
    82: img_9,
    85: img_7,
    86: img_8,
    95: img_2,
    96: img_2,
    99: img_2
  };

  const imagesNight = {
    0: img_24b,
    1: img_22b,
    2: img_21b,
    3: img_23b,
    45: img_16,
    48: img_16,
    51: img_15,
    53: img_15,
    55: img_14,
    56: img_13,
    57: img_13,
    61: img_12,
    63: img_12,
    65: img_9,
    66: img_8,
    67: img_3,
    71: img_7,
    73: img_7,
    75: img_6,
    77: img_5,
    80: img_12,
    81: img_12,
    82: img_9,
    85: img_7,
    86: img_8,
    95: img_2,
    96: img_2,
    99: img_2
  };

  return img_sad

  if (is_day === 1)
  {
    return imagesDay[number]
  }
  else
  {
    return imagesNight[number]
  }
  
}

const Weather = (props) => {

  const [weatherData, setWeatherData] = useState([]);

  useEffect(() => {
    fetch(props.api)
    .then((response) => response.json())
    .then((data) => setWeatherData(data))
  }, []);

  return (
    <div className='weatherwidget'>
      <div className='weatherWidget__info'>
        <div className='weatherwidget__location'>{props.location}</div>
        <div className='weatherwidget__temperature'>
          {
            weatherData && weatherData.current_weather && (weatherData.current_weather.temperature + '°C' )
          }
        </div>
      </div>
      <img src={weatherData && weatherData.current_weather && (GetWeatherImage(weatherData.current_weather.weathercode, weatherData.current_weather.is_day))} alt='img'></img>
    </div>
  )
}

export default Weather
