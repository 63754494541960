import React from 'react';
import './brand.css';
import { discord, steam, youtube } from './imports';

const Brand = () => {
  return (
    <div className='oldsquad__brand section__padding'>
      <div>
        <img src={discord} alt='discord'></img>
      </div>
      <div>
        <img src={steam} alt='steam'></img>
      </div>
      <div>
        <img src={youtube} alt='youtube'></img>
      </div>
    </div>
  )
}

export default Brand
