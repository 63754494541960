import React from 'react'
import { Link } from 'react-router-dom'
import Leaderboard from '../containers/leaderboard/Leaderboard'
import './discord.css'

const Discord = () => {
  return (
    <div className='discord'>
      <div className='discord__content'>
        <div className='discord__description'>
          <div className='discord__description-general'>
            <h1 className='gradient__text'>
              What can you find in our Discord server?
            </h1>
            <p>
              Other than the good company of everyone, you can find several custom Discord bots developed by and for us in this server. <br/>
              One of these bots is Shaggy, which handles the features mentioned below.
              
            </p>
          </div>
          <div className='discord__description-features'>
            <h1 className='gradient__text'>
              Bot features
            </h1>
            <p>
              - Leveling <br/>
              - Credits (aka Grettums) <br/>
              - Steam rewards
            </p>
          </div>
          <div className='discord__description-leveling'>
            <h1 className='gradient__text'>
              How does the leveling system work?
            </h1>
            <p>
            This leveling system is designed to reward users for their activity in voice channels.<br/>
            Users earn experience points based on how long they are active in a voice channel, with bonuses awarded for screen sharing and camera usage, and for being in larger voice channels.<br/>
            As users level up, they receive grettums that can be used to unlock rewards.<br/>
            Specifically, you receive 25 grettums every time you reach a multiple of five in your level.
            </p>
          </div>
          <div className='discord__linkbox'>
            <Link to={"https://discord.gg/g6FcyxCBGT"} target='blank'>Join server</Link>
          </div>
        </div>
        <div className='discord__leaderboard'>
          <Leaderboard></Leaderboard>
        </div>
      </div>
      
    </div>
  )
}

export default Discord