import React from 'react'
import './header.css'

const Header = () => {
  return (
    <div className='oldsquad__header section__padding' id='home'>
      <div className='oldsquad__header-content'>
        <h1 className='gradient__text'>Join the community</h1>
        <p> Welcome to Old Squad, a haven for gaming enthusiasts, gearheads, code wizards, and everyone in between!<br/>
            Looking for a diverse, friendly crowd that shares your interests, whatever they may be?<br/><br/> You've come to the right place!<br/><br/> Our community unites people from all walks of life, celebrating not just our love for gaming, cars, and programming, but also welcoming those with unique and varied hobbies.<br/><br/>
            So, what are you waiting for?<br/> Join Old Squad and dive into a world where friendships are formed, epic gaming sessions are enjoyed, cars are discussed, code is crafted, and new passions are discovered. There's something for everyone here!<br/><br/>
            Sign up now and let's explore, create, and game together!</p>
        <div className='oldsquad__header-content__input'>
          <input type='email' placeholder='Your Email Address'/>
          <button type='button'>Join now!</button>
        </div>
        <div className='oldsquad__header-content__people'>
          <p>20 people online last 7 days.</p>
        </div>
      </div>
    </div>
  )
}

export default Header
