import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Footer, Blog, Possibility, Features, Header, Whatoldsquad } from './containers'
import { CTA, Brand, Navbar } from './components'
import './App.css'

import Layout from './pages/layout'
import Discord from './pages/discord'
import Home from './pages/home'
import About from './pages/about'



const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="discord" element={<Discord />} />
            <Route path="about" element={<About />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <div className='App'>
        <Brand />
        <Whatoldsquad />
        <Features />
        <Possibility />
        <CTA />
        <Blog />
        <Footer />
      </div>
    </div>
    
  )
}

export default App
