import React, {useState, useEffect } from 'react'
import './leaderboard.css'

const Member = (data) => {
  let array = data.member;
  return(
  <div className='leaderboard__member'>
    <div className='leaderboard__name'>
      {array[0]}
    </div>

    <div className='leaderboard__level'>
      {array[1]}
    </div>

    <div className='leaderboard__xp'>
      {array[2]}
    </div>
  </div>)
}

const Leaderboard = () => {

    const [leaderboardData, setLeaderboardData] = useState([]);

    useEffect(() => {
       fetch('https://api.oldsquad.net/api/leaderboard') //   fetch('http://127.0.0.1:5000/api/leaderboard')
      .then((response) => response.json())
      .then((data) => setLeaderboardData(data))
    }, []);


  return (
    <div className='leaderboard'>
      <div className='leaderboard__title gradient__text'>
          Leaderboard
        </div>
    
      <div className='leaderboard__data'>
          <div className='leaderboard__member leaderboard__member--title'>
              <div className='leaderboard__nametitle'>
              Member
              </div>

              <div className='leaderboard__leveltitle'>
              Level
              </div>

              <div className='leaderboard__xptitle'>
              XP
              </div>
          </div>
          
          {
              // x = data, i = index
            leaderboardData.map((x, i) => {
              return <Member key={i} member={x}></Member>
            })
          }
      </div>
    </div>
  )
}

export default Leaderboard
