import React from 'react'
import { Header, Weather } from '../containers'
import './home.css'

const Home = () => {
  return (
    <div>
      <div className='gradient__bg'>
          <Header />
          <div className='weather'>
            <Weather location='Trondheim, Norway' api='https://api.open-meteo.com/v1/forecast?latitude=63.4305&longitude=10.3951&current_weather=true&hourly=temperature_2m,relativehumidity_2m,windspeed_10m'/>
            <Weather location='Oslo, Norway' api='https://api.open-meteo.com/v1/forecast?latitude=59.9139&longitude=10.7522&current_weather=true&hourly=temperature_2m,relativehumidity_2m,windspeed_10m'/>
            <Weather location='Sofia, Bulgaria' api='https://api.open-meteo.com/v1/forecast?latitude=42.6975&longitude=23.3242&current_weather=true&hourly=temperature_2m,relativehumidity_2m,windspeed_10m'/>
          </div>
        </div>
    </div>
  )
}

export default Home