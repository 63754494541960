import React from 'react'
import './blog.css'

const Blog = () => {
  return (
    <div>
    </div>
  )
}

export default Blog
