import React, {useState} from 'react'
import {RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { Link } from 'react-router-dom'
import './navbar.css'
import logo from '../../assets/old-squad-logo-without-slogan.png'


const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false)

  return (
    <div className='oldsquad__navbar'>
      <div className='oldsquad__navbar-links'>
        <div className='oldsquad__navbar-links_logo'>
          <Link to={'/'}>
            <img src={logo} alt='Logo'/>
          </Link>          
        </div>
        <div className='oldsquad__navbar-links_container'>
          <Link to={'/'}>Home</Link>
          <Link to={'/discord'}>Discord</Link>
          <Link to={'/about'}>About</Link>
        </div>
      </div>
      <div className='oldsquad__navbar-sign'>
        <a href='#login'>Login</a>
        <button type='button'>Register</ button>
      </div>
      <div className='oldsquad__navbar-menu'>
        {toggleMenu 
          ?<RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          :<RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
        }
        {toggleMenu && (
          <div className='oldsquad__navbar-menu_container scale-up-center'>
            <div className='oldsquad__navbar-menu_container-links'>
              <a href='#home'>Home</a>
              <a href='#whatis'>What is OldSquad?</a>
              <a href='#discord'>Discord</a>
              <a href='#library'>Library</a>
              <div className='oldsquad__navbar-menu_container-links-sign'>
                <a href='#login'>Login</a>
                <button type='button'>Register</ button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar
